<template>
    <div class="container mb-60 baojia-box liucheng-top rounded-lg">
        <v-img  src="@/assets/images/other/download-bg.png">
            <div class="magin-bg">
                <p class="text-h4 white--text">下载返利APP！</p>
                <p  class="text-h4  white--text">开启你的优惠生活</p>
                <p class=" white--text mt-6 mb-6">你身边的省钱助手！</p>
                <div class="d-flex">
                    <div>
                        <v-card width="200" height="40" class="rounded-pill d-flex align-center justify-center" flat>
                            <div>  
                            <v-img  width="24" class="mr-2" src="@/assets/images/other/red-apple.png"></v-img>

                            </div>
                            <span class="red--text ">{{$t('iPhone版下载')}}</span>
                        </v-card>
                        <v-card width="200" height="40"  class="mt-8 rounded-pill d-flex align-center justify-center" flat>
                            <div>

                            <v-img class="mr-2" width="24" src="@/assets/images/other/red-android.png"></v-img>
                            </div>
                            <span class="red--text ">{{$t('Andraid版下载')}}</span>
                        </v-card>
                    </div>  
                    <div class="ml-10">
                        <v-img class="rounded" :src="QRImgUrl" ></v-img>
                    </div>
                </div>
            </div>
        </v-img>
    </div>
</template>
<script>
import QRCode from 'qrcode'
export default {

    data(){
        return{
            QRImgUrl:"kkkkk",
      QRlink:'www.xxx.com'
        }
    },
    mounted(){
         this.getQRcode()
    },
    methods:{
        getQRcode(){
      let opts = {
        errorCorrectionLevel: "L",//容错级别
        type: "image/png",//生成的二维码类型
        quality:1,//二维码质量
        margin: 1,//二维码留白边距
        width: 120,//宽
        height: 120,//高
        text: "http://www.xxx.com",//二维码内容
        color: {
              dark: "#666666",//前景色
              light: "#fff"//背景色
        }
      };
  		QRCode.toDataURL(this.QRlink, opts , (err, url) => {
          if (err)throw err
          //将生成的二维码路径复制给data的QRImgUrl
          this.QRImgUrl= url
        })
    },
    }
}
</script>
<style scoped>

.magin-bg{
    margin: 120px 0 0 180px;
}
</style>
<style>
.main-content{
    min-height: 600px !important;
}
</style>